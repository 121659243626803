<template>
    <div class="d-flex list-container">
        <div class="bottom d-flex">
            <div class="left d-flex">
                <div class="bottom-title">非四方一致意见</div>
                <div class="unConfirmedList-box">
                    <div v-for="(item, index) in unUnifiedUnConfirmedList" :key="index">
                        <div class="type"><span>{{index + 1}}、</span> {{ item.questionTypeName }}</div>
                        <el-card v-for="(val, i) in item.opinionSummaryUnconfirmedList" :key="i">
                            <el-tag>{{ '提出人：' + val.userNames }}</el-tag>
                            <div>位置：{{ formatPath(val.drProjectFilePath) }}{{val.opinionType===124?val.drawingName:''}}{{val.opinionType===126?val.locationDescription:''}}<el-link v-if="val.positionImg && val.positionImg.length > 0" type="primary" @click="checkScreenshots(val.positionImg)">查看截图</el-link></div>
                            <div>审查内容：{{ val.confirmProblem }},{{val.ddIdQuestionTypeValue}}({{val.ddIdQuestionPropertieValue}})</div>
                        </el-card>
                    </div>
                </div>
            </div>
            <div class="right d-flex">
                <div class="bottom-title">四方一致意见</div>
                <div class="unConfirmedList-box">
                    <div v-for="(item, index) in unifiedUnConfirmedList" :key="index">
                        <div class="type"><span>{{index + 1}}、</span> {{ item.questionTypeName }}</div>
                        <el-card v-for="(val, i) in item.opinionSummaryUnconfirmedList" :key="i">
                            <el-tag>{{ '提出人：' + val.userNames }}</el-tag>
                            <div>位置：{{ formatPath(val.drProjectFilePath) }}{{val.opinionType===124?val.drawingName:''}}{{val.opinionType===126?val.locationDescription:''}}<el-link v-if="val.positionImg && val.positionImg.length > 0" type="primary" @click="checkScreenshots(val.positionImg)">查看截图</el-link></div>
                            <div>审查内容：{{ val.confirmProblem }},{{val.ddIdQuestionTypeValue}}({{val.ddIdQuestionPropertieValue}})</div>
                        </el-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Toolbar from "../../components/Toolbar";
import { getQuesTypeSummaryUnconfirmed } from "@/api/api";
export default {
    components: { Toolbar },
    props: {
        needData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            currentProject: undefined,
            currentProjectName: '',
            unifiedUnConfirmedList: [],
            unUnifiedUnConfirmedList: [],
            showScreenshotVisible: false,
            positionImg:'',
            confirmLoading: false,
        };
    },
    watch: {
        'currentProject': function(){
            if(this.currentProject){
                this.getUnifiedUnConfirmedList();
                this.getUnunifiedUnConfirmedList();
            }
        }
    },
    created() {
        this.currentProject = this.needData;
    },
    methods: {
        getUnifiedUnConfirmedList() {
            if(!this.currentProject || !this.currentProject.id){
                return;
            }
            var params = {
                projectId: this.currentProject.id,
                flagGreater2: true,
            }
            getQuesTypeSummaryUnconfirmed(params).then(res => {
                var result = res.data
                if(result.success){
                    this.unifiedUnConfirmedList = result.response;
                }else{
                    this.$message.error(result.message);
                }
            })
        },
        getUnunifiedUnConfirmedList() {
            if(!this.currentProject || !this.currentProject.id){
                return;
            }
            var params = {
                projectId: this.currentProject.id,
                flagLess2: true,
            }
            getQuesTypeSummaryUnconfirmed(params).then(res => {
                var result = res.data
                if(result.success){
                    this.unUnifiedUnConfirmedList = result.response;
                }else{
                    this.$message.error(result.message);
                }
            })
        },
        formatPath(path) {
            if(path) {
                return path.replace(/(.*?)\/(.*?)\/(.*?)\//, '');
            }
        },
        // 查看截图
        checkScreenshots(positionImg) {
            this.showScreenshotVisible = true;
            this.positionImg = positionImg;
        },
    },
};
</script>

<style lang="stylus" scoped>
.d-flex {
  display: flex;
}
.list-container {
  flex-direction: column;

  .top {
    height: 40px;
    padding-bottom: 10px;
    padding-left:10px;
    border-bottom:1px solid #fafafa; 
  }
  .bottom{
    height: 660px;
    padding-bottom:20px;
    overflow-y: scroll;

    .bottom-title{
        font-weight:bold;
        font-size:16px;
        justify-content: space-between;
    }
    .left, .right{
        flex: 1;
        flex-direction: column;
        padding:10px 20px;

        .unConfirmedList-box{
            margin-top:20px;
            
            .type {
                font-size: 18px;
            }

            >>>.el-card{
                margin-bottom:20px;
                .el-card__body{
                    div{
                        word-break: break-all;
                    }
                }
            }
        }
    }
  }
}
</style>
