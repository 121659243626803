<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="年份">
                        <el-select v-model="filters.year" @change="_getProjectNameList" placeholder="年份" clearable @clear='handleClear'>
                            <el-option
                                v-for="item in yearArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="月份">
                        <el-select v-model="filters.month" @change="_getProjectNameList" placeholder="月份" clearable @clear='handleClear'>
                            <el-option
                                v-for="item in monthArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="项目状态">
                        <el-select v-model="filters.entyType" @change="_getProjectNameList" placeholder="项目状态" clearable @clear='handleClear'>
                            <el-option
                                v-for="item in entyTypeArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="项目">
                        <el-select v-model="selectProjectId" @change="getProjectInfo" placeholder="选择项目" clearable>
                            <el-option
                                v-for="item in pNameList"
                                :key="item.projectId"
                                :label="item.projectName"
                                :value="item.projectId"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 项目统计信息 -->
        <el-row>
            <vue-step
                :now-step="nowStep"
                :step-list="stepList"
                @callFunction="callFunction"
            />
        </el-row>
        <!-- 列表 -->
        <div style="margin: 30px; text-align: center;">
            <el-radio-group v-model="ddIdTimeType" @change="handleChangeType" >
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="151">月报</el-radio>
                <el-radio :label="150">年报</el-radio>
            </el-radio-group>
        </div>
        <el-table
            :data="tableData"
            border
            style="width: 100%"
            v-loading="listLoading"
            ref="multipleTable"
            @current-change='currentChange'
            :highlight-current-row='true'
        >
            <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>
            <el-table-column prop="areaName" label="区(县)"  align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="ddIdTimeType" label="简报类型" align="center" width="90" show-overflow-tooltip>
                <template slot-scope='scope'>
                    <el-tag v-if="scope.row.ddIdTimeType == 0" type="">全部</el-tag>
                    <el-tag v-else-if="scope.row.ddIdTimeType == 151" type="">月报</el-tag>
                    <el-tag v-else>年报</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="cycleTime" label="月（年）份" align="center" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="createTime" label="生成日期" align="center" width="120" show-overflow-tooltip>
              <template slot-scope='scope'>
                {{scope.row.createTime.split(' ')[0]}}
              </template>
            </el-table-column>
            <el-table-column prop="totalProjectNum" label="项目总数" align="center" width="120" show-overflow-tooltip>
                <template slot-scope='scope'>
                    <a @click="showTotalProject(scope.row.id)" style="color: #409EFF;cursor: pointer;">{{scope.row.totalProjectNum}}</a>
                </template>
            </el-table-column>
            <el-table-column prop="finishProjectNum" label="已完成" align="center" width="120" show-overflow-tooltip>
                <template slot-scope='scope'>
                    <a @click="showFinishedProject(scope.row.id)" style="color: #409EFF;cursor: pointer;">{{scope.row.finishProjectNum}}</a>
                </template>
            </el-table-column>
            <el-table-column prop="expiredProjectNum" label="已逾期" align="center" width="120" show-overflow-tooltip>
                <template slot-scope='scope'>
                    <a @click="showExpiredProject(scope.row.id)" style="color: #409EFF;cursor: pointer;">{{scope.row.expiredProjectNum}}</a>
                </template>
            </el-table-column>
            <el-table-column prop="totalQuestionNum" label="违反问题总数" align="center" width="120" show-overflow-tooltip>
                <template slot-scope='scope'>
                    <a @click="showProblem(scope.row.id)" style="color: #409EFF;cursor: pointer;">{{scope.row.totalQuestionNum}}</a>
                </template>
            </el-table-column>
            <el-table-column prop="typeOneQuestionNum" label="违反强条数" align="center" width="120" show-overflow-tooltip>
                <template slot-scope='scope'>
                    <a @click="showTypeOneProblem(scope.row.id)" style="color: #409EFF;cursor: pointer;">{{scope.row.typeOneQuestionNum}}</a>
                </template>
            </el-table-column>
            <el-table-column prop="typeTwoQuestionNum" label="违反必须条数" align="center" width="120" show-overflow-tooltip>
                <template slot-scope='scope'>
                    <a @click="showTypeTwoProblem(scope.row.id)" style="color: #409EFF;cursor: pointer;">{{scope.row.typeTwoQuestionNum}}</a>
                </template>
            </el-table-column>
            <el-table-column prop="typeThreeQuestionNum" label="违反应条数" align="center" width="120" show-overflow-tooltip>
                <template slot-scope='scope'>
                    <a @click="showTypeThreeProblem(scope.row.id)" style="color: #409EFF;cursor: pointer;">{{scope.row.typeThreeQuestionNum}}</a>
                </template>
            </el-table-column>
            <el-table-column prop="SendTime" label="操作" align="center" width="90">
                <template slot-scope='scope'>
                    <el-button @click="showDetail(scope.row.filePath)" type="text">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <!-- 项目信息 -->
        <el-dialog
            title="项目信息"
            :visible.sync="dialogProjectInfoVisible"
            v-model="dialogProjectInfoVisible"
            :close-on-click-modal="false"
            :width='viewsApplicationWidth'
            :destroy-on-close="true"
            class="viewProject"
        >
            <ProjectView @activeChange="activeChange" :needData="project"></ProjectView>
        </el-dialog>
        <!-- 在线图审 -->
        <el-dialog
            title="在线图审"
            :visible.sync="ParticipantsDialog"
            v-model="ParticipantsDialog"
            :close-on-click-modal="false"
            width='50%'
        >
            <el-table
                :data="ParticipantsData"
                border
                style="width: 100%"
                v-loading="ParticipantsLoading"
                ref="multipleTable"
                :highlight-current-row='true'
                :cell-style="rowClass"
            >
                <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
                <el-table-column prop="participationTypeName" label="类型" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="name" label="姓名" align="center" show-overflow-tooltip>
                    <template slot-scope='scope'>
                        {{scope.row.name}}（{{scope.row.applyStatusName}}）
                    </template>
                </el-table-column>
                <el-table-column prop="phoneNumber" label="手机号" align="center" show-overflow-tooltip></el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="ParticipantsDialog = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 专家审核 -->
        <el-dialog
            title="专家审核"
            :visible.sync="dialogExpertAuditVisible"
            v-model="dialogExpertAuditVisible"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            class="viewProject"
            width='70%'
        >
            <Opinion :needData="project"></Opinion>
        </el-dialog>
        <!-- 初审报告 -->
        <el-dialog
            title="初审报告"
            :visible.sync="preliminaryReportDialog"
            v-model="preliminaryReportDialog"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            width='50%'
        >
            <PreliminaryReport :projectId="preliminaryProjectId"></PreliminaryReport>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="preliminaryReportDialog = false">关闭</el-button>
            </div>
        </el-dialog>
        <!-- 图审报告 -->
        <el-dialog
            title="图审报告"
            :visible.sync="dialogReportVisible"
            v-model="dialogReportVisible"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            width='50%'
        >
            <AuditReportComponent :projectId="preliminaryProjectId"></AuditReportComponent>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="dialogReportVisible = false">关闭</el-button>
            </div>
        </el-dialog>
        <!-- 项目总数 -->
        <el-dialog
            title="项目总数"
            :visible.sync="TotalProjectDialog"
            v-model="TotalProjectDialog"
            :close-on-click-modal="false"
            width='50%'
        >
            <el-table
                :data="TotalProjectData"
                border
                style="width: 100%"
                v-loading="TotalProjectLoading"
                ref="multipleTable"
                :highlight-current-row='true'
                :cell-style="rowClass"
            >
                <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
                <el-table-column prop="projectName" label="项目名称" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="projectArea" label="建筑面积" align="center" show-overflow-tooltip></el-table-column>
            </el-table>
        </el-dialog>
        <!-- 已完成项目数 -->
        <el-dialog
            title="已完成项目数"
            :visible.sync="FinishedProjectDialog"
            v-model="FinishedProjectDialog"
            :close-on-click-modal="false"
            width='50%'
        >
            <el-table
                :data="FinishedProjectData"
                border
                style="width: 100%"
                v-loading="FinishedProjectLoading"
                ref="multipleTable"
                :highlight-current-row='true'
                :cell-style="rowClass"
            >
                <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
                <el-table-column prop="projectName" label="项目名称" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="projectArea" label="建筑面积" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="projectPeriod" label="承诺办结日期" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="conclusionTime" label="实际审结日期" align="center" show-overflow-tooltip></el-table-column>
            </el-table>
        </el-dialog>
        <!-- 已逾期项目数 -->
        <el-dialog
            title="已逾期项目数"
            :visible.sync="ExpiredProjectDialog"
            v-model="ExpiredProjectDialog"
            :close-on-click-modal="false"
            width='50%'
        >
            <el-table
                :data="ExpiredProjectData"
                border
                style="width: 100%"
                v-loading="ExpiredProjectLoading"
                ref="multipleTable"
                :highlight-current-row='true'
                :cell-style="rowClass"
            >
                <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
                <el-table-column prop="projectName" label="项目名称" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="projectArea" label="建筑面积" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="projectPeriod" label="承诺办结日期" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="conclusionTime" label="实际审结日期" align="center" show-overflow-tooltip></el-table-column>
            </el-table>
        </el-dialog>
        <!-- 违反问题总数 -->
        <el-dialog
            title="违反问题总数"
            :visible.sync="ProblemDialog"
            v-model="ProblemDialog"
            :close-on-click-modal="false"
            width='50%'
        >
            <div v-for="(item, index) in ProblemData"
                :key="item.value"
                :label="item.name"
                :value="item.value"
            >
                <div>位置：{{ item.fileName }}</div>
                <!-- <div style="margin-bottom: 10px;"><span>{{index+1}}、</span>{{ item.confirmProblem }}</div> -->
                <div style="margin-bottom: 10px;">
                    <div v-if="item.ddIdQuestionPropertie === 20"><span>{{index+1}}、</span>{{ item.confirmProblem }}(强)</div>
                    <div v-else-if="item.ddIdQuestionPropertie === 21"><span>{{index+1}}、</span>{{ item.confirmProblem }}(必须)</div>
                    <div v-else-if="item.ddIdQuestionPropertie === 22"><span>{{index+1}}、</span>{{ item.confirmProblem }}(应)</div>
                    <div v-else-if="item.ddIdQuestionPropertie === 23"><span>{{index+1}}、</span>{{ item.confirmProblem }}(宜)</div>
                    <div v-else-if="item.ddIdQuestionPropertie === 24"><span>{{index+1}}、</span>{{ item.confirmProblem }}(可)</div>
                </div>
            </div>
        </el-dialog>
        <!-- 违反强条数 -->
        <el-dialog
            title="违反强条数"
            :visible.sync="TypeOneProblemDialog"
            v-model="TypeOneProblemDialog"
            :close-on-click-modal="false"
            width='50%'
        >
            <div v-for="(item, index) in TypeOneProblemData"
                :key="item.value"
                :label="item.name"
                :value="item.value"
            >
                <div>位置：{{ item.fileName }}</div>
                <div style="margin-bottom: 10px;">
                    <div v-if="item.ddIdQuestionPropertie === 20"><span>{{index+1}}、</span>{{ item.confirmProblem }}(强)</div>
                    <div v-else-if="item.ddIdQuestionPropertie === 21"><span>{{index+1}}、</span>{{ item.confirmProblem }}(必须)</div>
                    <div v-else-if="item.ddIdQuestionPropertie === 22"><span>{{index+1}}、</span>{{ item.confirmProblem }}(应)</div>
                    <div v-else-if="item.ddIdQuestionPropertie === 23"><span>{{index+1}}、</span>{{ item.confirmProblem }}(宜)</div>
                    <div v-else-if="item.ddIdQuestionPropertie === 24"><span>{{index+1}}、</span>{{ item.confirmProblem }}(可)</div>
                </div>
            </div>
        </el-dialog>
        <!-- 违反必须条数 -->
        <el-dialog
            title="违反必须条数"
            :visible.sync="TypeTwoProblemDialog"
            v-model="TypeTwoProblemDialog"
            :close-on-click-modal="false"
            width='50%'
        >
            <div v-for="(item, index) in TypeTwoProblemData"
                :key="item.value"
                :label="item.name"
                :value="item.value"
            >
                <div>位置：{{ item.fileName }}</div>
                <div style="margin-bottom: 10px;">
                    <div v-if="item.ddIdQuestionPropertie === 20"><span>{{index+1}}、</span>{{ item.confirmProblem }}(强)</div>
                    <div v-else-if="item.ddIdQuestionPropertie === 21"><span>{{index+1}}、</span>{{ item.confirmProblem }}(必须)</div>
                    <div v-else-if="item.ddIdQuestionPropertie === 22"><span>{{index+1}}、</span>{{ item.confirmProblem }}(应)</div>
                    <div v-else-if="item.ddIdQuestionPropertie === 23"><span>{{index+1}}、</span>{{ item.confirmProblem }}(宜)</div>
                    <div v-else-if="item.ddIdQuestionPropertie === 24"><span>{{index+1}}、</span>{{ item.confirmProblem }}(可)</div>
                </div>
            </div>
        </el-dialog>
        <!-- 违反应条数 -->
        <el-dialog
            title="违反应条数"
            :visible.sync="TypeThreeProblemDialog"
            v-model="TypeThreeProblemDialog"
            :close-on-click-modal="false"
            width='50%'
        >
            <div v-for="(item, index) in TypeThreeProblemData"
                :key="item.value"
                :label="item.name"
                :value="item.value"
            >
                <div>位置：{{ item.fileName }}</div>
                <div style="margin-bottom: 10px;">
                    <div v-if="item.ddIdQuestionPropertie === 20"><span>{{index+1}}、</span>{{ item.confirmProblem }}(强)</div>
                    <div v-else-if="item.ddIdQuestionPropertie === 21"><span>{{index+1}}、</span>{{ item.confirmProblem }}(必须)</div>
                    <div v-else-if="item.ddIdQuestionPropertie === 22"><span>{{index+1}}、</span>{{ item.confirmProblem }}(应)</div>
                    <div v-else-if="item.ddIdQuestionPropertie === 23"><span>{{index+1}}、</span>{{ item.confirmProblem }}(宜)</div>
                    <div v-else-if="item.ddIdQuestionPropertie === 24"><span>{{index+1}}、</span>{{ item.confirmProblem }}(可)</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getProjectNameList, getProjectById, getProjectStInfo, getWorkReportList, getProjectParticipants, getProjectDetailByWorkReport, getQuestionDetailByWorkReport } from '@/api/api'
import ProjectView from "../drProjectManager/projectView.vue";
import VueStep from './Step.vue'
import Opinion from './Opinion.vue'
import PreliminaryReport from "../checkingManager/preliminaryReportComponent.vue"
import AuditReportComponent from "../checkingManager/auditReportComponent"

export default {
    components:{Toolbar,ProjectView, VueStep, Opinion, PreliminaryReport, AuditReportComponent},
    data() {
        return {
            // 项目查看
            viewsApplicationWidth: '70%',
            dialogProjectInfoVisible: false,
            ParticipantsDialog: false,
            dialogExpertAuditVisible: false,
            preliminaryReportDialog: false,
            dialogReportVisible: false,
            dialogArchiveVisible: false,
            utils: '',
            project: null,
            preliminaryProjectId: '',
            filters: {
                year: 2023,
                month: 1,
                entyType: 1,
            },
            smsTemplateArr: [],
            pushTemplateArr: [],
            yearArr: [],
            monthArr: [
                {name: '1',value: 1},
                {name: '2',value: 2},
                {name: '3',value: 3},
                {name: '4',value: 4},
                {name: '5',value: 5},
                {name: '6',value: 6},
                {name: '7',value: 7},
                {name: '8',value: 8},
                {name: '9',value: 9},
                {name: '10',value: 10},
                {name: '11',value: 11},
                {name: '12',value: 12},
            ],
            entyTypeArr: [
                {name: '已完成项目登记',value: 1},
                {name: '未完成项目登记',value: 0},
            ],
            pNameList: [],
            selectProjectId: null,
            ddIdTimeType: 0,
            nowStep: 0,
            stepList: {},
            tableData: [],
            listLoading: false,
            //关于分页的obj
            pages: {
                pageSize: 10,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            // 项目参与人员
            // ParticipantsDialog: false,
            ParticipantsLoading: false,
            ParticipantsData: [],

            // 项目总数
            TotalProjectDialog: false,
            TotalProjectLoading: false,
            TotalProjectData: [],

            // 已完成项目总数
            FinishedProjectDialog: false,
            FinishedProjectLoading: false,
            FinishedProjectData: [],

            // 已逾期项目总数
            ExpiredProjectDialog: false,
            ExpiredProjectLoading: false,
            ExpiredProjectData: [],

            // 违反问题总数
            ProblemDialog: false,
            ProblemData: false,
            TypeOneProblemDialog: false,
            TypeOneProblemData: false,
            TypeTwoProblemDialog: false,
            TypeTwoProblemData: false,
            TypeThreeProblemDialog: false,
            TypeThreeProblemData: false,
        }
    },
    created(){
        this.utils = util
        this.filters.month = new Date().getMonth() + 1;
		    // 获取当前日期
		    let currentDate = new Date();
				// 提取当前年份
		    let currentYear = currentDate.getFullYear();
		    // 从2023年开始向数组添加对象
		    for (let year = currentYear; year >= 2023; year--) {
			    this.yearArr.push({ name: year.toString(), value: year });
		    }
	      this.filters.year = currentYear
        this._getProjectNameList();
				
        this.getDataList();
    },
    methods: {
        callFunction(item) {
            switch(item) {
                case 1:
                    if(this.project) {
                        this.dialogProjectInfoVisible = true;
                        this.ParticipantsDialog = false;
                        this.dialogExpertAuditVisible = false;
                        this.preliminaryReportDialog = false;
                        this.dialogReportVisible = false;
                        this.dialogArchiveVisible = false;
                    }else {
                        this.$message({
                            message: '请选择项目',
                            type: 'warning'
                        });
                    }
                    break
                case 2:
                    if(this.project) {
                        this.dialogProjectInfoVisible = false;
                        this.ParticipantsDialog = true;
                        this.dialogExpertAuditVisible = false;
                        this.preliminaryReportDialog = false;
                        this.dialogReportVisible = false;
                        this.dialogArchiveVisible = false;
                        this.viewsParticipants();
                    }else {
                        this.$message({
                            message: '请选择项目',
                            type: 'warning'
                        });
                    }
                    break
                case 3:
                    if(this.project) {
                        this.dialogProjectInfoVisible = false;
                        this.ParticipantsDialog = false;
                        this.dialogExpertAuditVisible = true;
                        this.preliminaryReportDialog = false;
                        this.dialogReportVisible = false;
                        this.dialogArchiveVisible = false;
                    }else {
                        this.$message({
                            message: '请选择项目',
                            type: 'warning'
                        });
                    }
                    break
                case 4:
                    if(this.project) {
                        this.dialogProjectInfoVisible = false;
                        this.ParticipantsDialog = false;
                        this.dialogExpertAuditVisible = false;
                        this.preliminaryReportDialog = true;
                        this.dialogReportVisible = false;
                        this.dialogArchiveVisible = false;
                    }else {
                        this.$message({
                            message: '请选择项目',
                            type: 'warning'
                        });
                    }
                    break
                case 5:
                    if(this.project) {
                        this.dialogProjectInfoVisible = false;
                        this.ParticipantsDialog = false;
                        this.dialogExpertAuditVisible = false;
                        this.preliminaryReportDialog = false;
                        this.dialogReportVisible = true;
                        this.dialogArchiveVisible = false;
                    }else {
                        this.$message({
                            message: '请选择项目',
                            type: 'warning'
                        });
                    }
                    break
                case 6:
                    if(this.project) {
                        this.dialogProjectInfoVisible = false;
                        this.ParticipantsDialog = false;
                        this.dialogExpertAuditVisible = false;
                        this.preliminaryReportDialog = false;
                        this.dialogReportVisible = false;
                        this.dialogArchiveVisible = true;
                    }else {
                        this.$message({
                            message: '请选择项目',
                            type: 'warning'
                        });
                    }
                    break
            }
        },
        activeChange(val) {
            if(val == '2') {
                this.viewsApplicationWidth = "1200px"
            } else {
                this.viewsApplicationWidth = "70%"
            }
        },
        // 列表查询接口
        _getProjectNameList(){
            this.pNameList = [];
            this.selectProjectId = null;
            var params = {
                year: this.filters.year,
                month: this.filters.month,
                type: this.filters.entyType,
            }
            getProjectNameList(params).then(res => {
                var result = res.data
                if(result.success) {
                    this.pNameList = [...result.response]
                }
            })
        },
        getProjectInfo(value){
            getProjectById({projectId: value}).then(res => {
                const {status, success, response} = res.data;
                if(status === 200 && success === true) {
                    this.project = response;
                    this.preliminaryProjectId = this.project.id
                }
            })
            getProjectStInfo(value).then(res => {
                const {status, success, response} = res.data;
                if(status === 200 && success === true) {
                    if(response.stepOneDate) {
                        this.nowStep = 1;
                    }
                    if(response.stepTwoDate) {
                        this.nowStep = 2;
                    }
                    if(response.stepThreeDate) {
                        this.nowStep = 3;
                    }
                    if(response.stepFourDate) {
                        this.nowStep = 4;
                    }
                    if(response.stepFiftyDate) {
                        this.nowStep = 5;
                    }
                    if(response.stepSixDate) {
                        this.nowStep = 6;
                    }
                    this.stepList = {
                        stepOneDate: response.stepOneDate,
                        stepOnePeriodDate: response.stepOnePeriodDate,
                        stepTwoDate: response.stepTwoDate,
                        stepThreeDate: response.stepThreeDate,
                        stepFourDate: response.stepFourDate,
                        stepFiftyDate: response.stepFiftyDate,
                        stepSixDate: response.stepSixDate,
                    }
                }
            })
        },
        // 列表查询接口
        getDataList(){
            this.listLoading = true
            getWorkReportList(this.ddIdTimeType, this.pages.pageIndex, this.pages.pageSize).then(res => {
                var result = res.data
                if(result.success) {
                    this.tableData = result.response.data
                    this.pages.dataCount = result.response.dataCount
                }
                this.listLoading = false
            })
        },
        handleChangeType(value) {
            this.ddIdTimeType = value;
            this.getDataList()
        },
        // 筛选框清空重新请求数据
        handleClear(){
            this._getProjectNameList()
        },
        // 查看项目
        viewsEntyTemplate(row) {
            this.project = row
            this.dialogProjectInfoVisible = true
        },
        // 项目参与人员
        viewsParticipants() {
            this.ParticipantsDialog = true,
            this.ParticipantsLoading = true,
            this.ParticipantsData = []
            var params = {
                projectId: this.project.id
            }
            getProjectParticipants(params).then(res => {
                var result = res.data
                if(result) {
                    this.ParticipantsData = result.response;
                } else {
                    this.$message.error("加载失败！")
                }
                this.ParticipantsLoading = false
            })
        },
        // 点击table某一行
        currentChange(val) {
            this.project = val
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getDataList();
        },
        handleSizeChange(val){
            this.pages.pageSize = val;
            this.getDataList();
        },
        // 时间格式化
        formatCreateTime: function(row, column) {
            return !row || row == ""
            ? ""
            : util.formatDate.format(new Date(row), "yyyy-MM-dd hh:mm");
        },
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
        // 列表样式
        rowClass(){
            return 'padding:8px!important;'
        },
        showTotalProject(id) {
            this.TotalProjectDialog = true,
            this.TotalProjectLoading = true,
            this.TotalProjectData = []
            getProjectDetailByWorkReport(id, 0).then(res => {
                var result = res.data
                if(result) {
                    this.TotalProjectData = result.response;
                } else {
                    this.$message.error("加载失败！")
                }
                this.TotalProjectLoading = false
            })
        },
        showFinishedProject(id) {
            this.FinishedProjectDialog = true,
            this.FinishedProjectLoading = true,
            this.FinishedProjectData = []
            getProjectDetailByWorkReport(id, 1).then(res => {
                var result = res.data
                if(result) {
                    this.FinishedProjectData = result.response;
                } else {
                    this.$message.error("加载失败！")
                }
                this.FinishedProjectLoading = false
            })
        },
        showExpiredProject(id) {
            this.ExpiredProjectDialog = true,
            this.ExpiredProjectLoading = true,
            this.ExpiredProjectData = []
            getProjectDetailByWorkReport(id, 2).then(res => {
                var result = res.data
                if(result) {
                    this.ExpiredProjectData = result.response;
                } else {
                    this.$message.error("加载失败！")
                }
                this.ExpiredProjectLoading = false
            })
        },
        showProblem(id) {
            this.ProblemDialog = true,
            this.ProblemData = []
            getQuestionDetailByWorkReport(id, 0).then(res => {
                var result = res.data
                if(result) {
                    this.ProblemData = result.response;
                } else {
                    this.$message.error("加载失败！")
                }
            })
        },
        showTypeOneProblem(id) {
            this.TypeOneProblemDialog = true,
            this.TypeOneProblemData = []
            getQuestionDetailByWorkReport(id, 1).then(res => {
                var result = res.data
                if(result) {
                    this.TypeOneProblemData = result.response;
                } else {
                    this.$message.error("加载失败！")
                }
            })
        },
        showTypeTwoProblem(id) {
            this.TypeTwoProblemDialog = true,
            this.TypeTwoProblemData = []
            getQuestionDetailByWorkReport(id, 2).then(res => {
                var result = res.data
                if(result) {
                    this.TypeTwoProblemData = result.response;
                } else {
                    this.$message.error("加载失败！")
                }
            })
        },
        showTypeThreeProblem(id) {
            this.TypeThreeProblemDialog = true,
            this.TypeThreeProblemData = []
            getQuestionDetailByWorkReport(id, 3).then(res => {
                var result = res.data
                if(result) {
                    this.TypeThreeProblemData = result.response;
                } else {
                    this.$message.error("加载失败！")
                }
            })
        },
        showDetail(filePath){
            window.open(`https://view.officeapps.live.com/op/view.aspx?src=${filePath}&wdOrigin=BROWSELINK`)
        }
    },
    watch: {

    },
}
</script>

<style lang="stylus" scoped>
    .projectName {
        text-align: center;
    }
    .projectSelect {
        margin-bottom: 15px;
    }
    .projectTitle {
        margin-bottom: 15px
    }
    .replyBtn {
        margin: 10px 0;
        color: #409eff;
        cursor: pointer;
        width: 30px;
    }
    .replyMessage {
        background: #eee;
        line-height: 30px;
        padding: 5px;
        border-radius: 2px;
        margin: 5px 15px;
    }
    .titleTimer {
        margin-left: 10px;
    }
    .specialReminder {
        width: 350px;
        height: 200px;
        background: #fff;
        border-radius: 5px;
        position: fixed;
        right: 7px;
        bottom: 7px;
        z-index: 10;
        border: 1px solid #999;
        overflow: hidden;
    }
    .reminderBtn {
        display: flex;
        justify-content: flex-end;
        margin-right: 10px;
    }
    .projectPra {

    }
    .projectBox {
        width: 90%;
        margin: 0 auto 0;
        height: 150px;
        overflow-y: auto;
    }
    .projectName {
        color: red;
        font-size: 14px;
    }
    .projectDay {
        color: red;
        font-size: 16px;
        margin-left: 10px;
    }
    .projectList {
        margin-top: 10px;
        font-size: 14px;
    }
    .miniBox {
        width: 45px;
        height: 45px;
        position: fixed;
        right: 7px;
        bottom: 7px;
        border-radius: 5px 5px 0 0;
        border-left: 2px solid #999;
        border-top: 2px solid #999;
        border-right: 2px solid #999;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .miniNodes {
        width: 25px;
        height: 25px;
        right: 7px;
        bottom: 7px;
        border-radius: 5px;
        border: 2px solid #999;
        z-index: 10;
    }
    .viewProject>>>.el-dialog__body {
        padding: 0px 20px 30px;
        margin-top: -15px;
    }
</style>
